import { wizardStep } from '@/common/enums';
import { mapActions, mapGetters } from 'vuex';
import { RESET_STATE } from '@/store/modules/carWizard.module';

const wizardMixin = {
  data() {
    return {
      wizardStep,
    };
  },
  computed: {
    ...mapGetters(['getWizardStep']),
  },
  methods: {
    ...mapActions({
      resetState: RESET_STATE,
    }),
    setDocumentTitle(step) {
      let title = 'Teklif al';
      if (step === 0) {
        title = 'Araç Bilgileri';
      } else if (step === 1) {
        title = 'Hasar Bilgileri';
      } else if (step === 2) {
        title = 'İletişim Bilgileri';
      } else if (step === 3) {
        title = 'SMS Doğrulama';
      } else if (step === 4) {
        title = 'Teklifimiz';
      } else if (step === 5) {
        title = 'Teklif Onayı';
      }
      document.title = `${this.getSite?.companyName} | ${title}`;
    },
    replaceRoute(step) {
      let path = '/';
      if (step === 0) {
        path = 'arac-bilgileri';
      } else if (step === 1) {
        path = 'hasar-bilgileri';
      } else if (step === 2) {
        path = 'iletisim-bilgileri';
      } else if (step === 3) {
        path = 'sms-dogrulama';
      } else if (step === 4) {
        path = 'teklifimiz';
      } else if (step === 5) {
        path = 'teklif-onay';
      }

      this.$router.replace({
        name: 'get-offer',
        params: {
          step: path,
        },
      });
    },
  },
};

export default wizardMixin;
