<template>
  <v-card color="transparent" flat v-if="getWizardStep === wizardStep.COMPLETE">
    <v-card-text>
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="d-flex justify-center">
            <div>
              <div class="text-center font-weight-bold text-h5">
                Sizinle en kısa sürede<br />
                iletişime geçeceğiz.
              </div>
              <div v-if="getTrackingNumber" class="d-flex align-center justify-center">
                <span class="text-body-1 font-weight-bold mr-1">Takip numaranız : </span>
                <span class="text-h5 font-weight-bold">{{ getTrackingNumber }}</span>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex justify-center">
            <object :data="`${$assetPath}complete.svg`" width="287" height="460"></object>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-center py-5">
      <v-btn
        large
        shaped
        color="primary"
        class="rounded-lg text-capitalize"
        v-text="'Ana Sayfaya Dön'"
        @click="resetProcess"
      ></v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import wizardMixin from '@/mixins/wizardMixin';
import { mapActions, mapGetters } from 'vuex';
import { RESET_STATE } from '@/store/modules/carWizard.module';

export default {
  name: 'WizardOffer',
  mixins: [wizardMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getTrackingNumber']),
  },
  created() {},
  methods: {
    ...mapActions({
      resetState: RESET_STATE,
    }),
    resetProcess() {
      this.resetState();
      this.$router.push({
        name: 'tour',
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
